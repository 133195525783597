// import logo from "./logo.svg";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Navigation from "./navigation/Navigation";
import Home from "./pages/Home";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="min-h-screen bg-black">
      {/* Navigation */}
      <Navigation />

      {/* Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
