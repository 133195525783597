import React from "react";
import Hero from "../components/hero/Hero";
import HeroSection from "../components/hero/HeroSection";
// import Navigation from "../navigation/Navigation";
// import AgentCluster from "../components/AgentCluster/AgentCluster";
import ClusterAgentsSlider from "../components/AgentCluster/ClusterAgentsSlider";
import AgentGrid from "../components/AgentCluster/AgentGrid";
import { salesAgents, marketingAgents } from "../utils/AgentsData";

function Home() {
  return (
    <>
      <HeroSection />

      <AgentGrid
        title="AI Agents for Marketing"
        agentCluster={marketingAgents}
      />
    </>
  );
}

export default Home;
