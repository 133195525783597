import {
  Sparkles,
  Bot,
  Mic,
  Target,
  Mail,
  Pencil,
  Search,
  Smartphone,
  BarChart,
  PenTool,
  Video,
  PhoneCall,
} from "lucide-react";

export const salesAgents = [
  {
    title: "Sales Representative AI",
    description:
      "Conducts product demonstrations and handles customer inquiries autonomously.",
    icon: <Bot className="w-6 h-6 text-blue-400" />,
    gradient: "from-blue-500/20 to-cyan-500/20",
    accent: "blue",
    originalIcon: "🤖",
  },
  {
    title: "Sales Call Analyzer",
    description:
      "Transcribes and analyzes sales calls in real-time, providing insights for improvement.",
    icon: <Mic className="w-6 h-6 text-green-400" />,
    gradient: "from-green-500/20 to-teal-500/20",
    accent: "green",
    originalIcon: "🎙️",
  },
  {
    title: "Lead Generation Assistant",
    description:
      "Identifies and qualifies potential leads by analyzing online behavior and industry trends.",
    icon: <Target className="w-6 h-6 text-red-400" />,
    gradient: "from-red-500/20 to-orange-500/20",
    accent: "red",
    originalIcon: "🎯",
  },
  {
    title: "Outreach Optimizer",
    description:
      "Crafts personalized email campaigns and optimizes content for maximum engagement.",
    icon: <Mail className="w-6 h-6 text-indigo-400" />,
    gradient: "from-indigo-500/20 to-purple-500/20",
    accent: "indigo",
    originalIcon: "📧",
  },
];

export const marketingAgents = [
  {
    title: "Social Media Storyteller AI",
    description:
      "Generates human-like, natural-sounding content for LinkedIn, Twitter, and TikTok scripts. Tailors content to your brand’s voice and optimizes for engagement.",
    icon: <PenTool className="w-6 h-6 text-purple-400" />,
    gradient: "from-purple-500/20 to-pink-500/20",
    accent: "purple",
    originalIcon: "✍️",
    keyFeatures: [
      "Generates human-like content for social media",
      "Tailors content to brand voice and audience",
      "Optimizes posts for engagement (hashtags, trends)",
      "Supports AI video generation for TikTok scripts",
    ],
    whyImportant:
      "Saves time for founders, ensures consistent high-quality content, and builds organic reach.",
    status: "Prototype in Progress",
  },
  {
    title: "Auto-Demo Pro",
    description:
      "Automates product demos via Zoom or Teams, handles real-time Q&A, and schedules follow-ups.",
    icon: <Video className="w-6 h-6 text-blue-400" />,
    gradient: "from-blue-500/20 to-cyan-500/20",
    accent: "blue",
    originalIcon: "📹",
    keyFeatures: [
      "Automates product demos and schedules meetings",
      "Real-time Q&A with deep product knowledge",
      "Handles objections and delegates to humans",
      "24/7 availability for global customers",
    ],
    whyImportant:
      "Reduces the need for human sales reps, increases conversion rates, and provides 24/7 availability.",
    status: "Live",
  },
  {
    title: "Cold Call Outreach AI Agent",
    description:
      "Automates cold calling with natural, conversational AI. Personalizes calls and handles objections.",
    icon: <PhoneCall className="w-6 h-6 text-green-400" />,
    gradient: "from-green-500/20 to-teal-500/20",
    accent: "green",
    originalIcon: "📞",
    keyFeatures: [
      "Automates cold calling with conversational AI",
      "Personalizes calls based on prospect data",
      "Handles objections and schedules follow-ups",
      "Tracks performance with detailed analytics",
    ],
    whyImportant:
      "Eliminates the fear of manual cold calling, scales outreach efforts, and increases lead generation.",
    status: "Prototype in Progress",
  },
  {
    title: "Email Alchemist",
    description:
      "Writes personalized, high-converting emails and optimizes campaigns for higher engagement.",
    icon: <Mail className="w-6 h-6 text-orange-400" />,
    gradient: "from-orange-500/20 to-red-500/20",
    accent: "orange",
    originalIcon: "📧",
    keyFeatures: [
      "Writes personalized, high-converting emails",
      "Optimizes subject lines, body content, and CTAs",
      "A/B tests email versions for best performance",
      "Integrates with CRMs and automates follow-ups",
    ],
    whyImportant:
      "Saves time on email crafting, improves campaign ROI, and ensures consistent follow-ups.",
    status: "Live",
  },
];

// export const marketingAgents = [
//   {
//     title: "Content Creation Assistant",
//     description:
//       "Generates blog posts, social media content, and ad copy tailored to your target audience.",
//     icon: <Pencil className="w-6 h-6 text-purple-400" />,
//     gradient: "from-purple-500/20 to-blue-500/20",
//     accent: "purple",
//     originalIcon: "✍️",
//   },
//   {
//     title: "SEO Strategist",
//     description:
//       "Analyzes keywords and optimizes website content to improve search engine rankings.",
//     icon: <Search className="w-6 h-6 text-cyan-400" />,
//     gradient: "from-cyan-500/20 to-sky-500/20",
//     accent: "cyan",
//     originalIcon: "🔍",
//   },
//   {
//     title: "Social Media Manager",
//     description:
//       "Schedules posts, engages with followers, and analyzes performance across platforms.",
//     icon: <Smartphone className="w-6 h-6 text-emerald-400" />,
//     gradient: "from-emerald-500/20 to-green-500/20",
//     accent: "emerald",
//     originalIcon: "📱",
//   },
//   {
//     title: "Marketing Campaign Optimizer",
//     description:
//       "Analyzes campaign performance and provides data-driven recommendations for improvement.",
//     icon: <BarChart className="w-6 h-6 text-rose-400" />,
//     gradient: "from-rose-500/20 to-pink-500/20",
//     accent: "rose",
//     originalIcon: "📊",
//   },
// ];
