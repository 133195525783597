// import React from "react";

// const HeroSection = () => {
//   return (
//     <div className="flex justify-center items-center min-h-screen">
//       <div className="relative min-h-screen w-[97%] bg-white flex items-center justify-center overflow-hidden rounded-2xl">
//         <div className="absolute inset-0 flex items-center justify-center opacity-5">
//           <div className="w-[800px] h-[800px] bg-gradient-to-r from-blue-200 to-purple-200 rounded-full blur-3xl animate-pulse" />
//         </div>

//         <div
//           className="absolute inset-0"
//           style={{
//             backgroundImage:
//               "radial-gradient(circle at 1px 1px, #f0f0f0 1px, transparent 0)",
//             backgroundSize: "40px 40px",
//           }}
//         ></div>

//         <div className="relative z-10 container mx-auto px-4">
//           <div className="max-w-4xl mx-auto text-center">
// <h1 className="font-mono text-4xl md:text-6xl font-bold text-black mb-6 tracking-tight">
//   AAAGentive
// </h1>

// <h1 className="font-mono mt-20 text-4xl md:text-6xl font-bold text-black mb-6 tracking-tight">
//   AI_AGENTS.init(
//   <span className="text-blue-600">future</span>)
// </h1>

//             <p className="font-mono text-gray-600 text-lg md:text-xl mb-12 tracking-wide">
//               $ deploying intelligent solutions for solo founders_
//             </p>

//             <button className="bg-black hover:bg-gray-800 text-white px-8 py-4 rounded-lg font-mono transition-colors">
//               {">"} initialize_platform()
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeroSection;

import React from "react";

const HeroSection = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="relative min-h-screen w-[96%] bg-white flex items-center justify-center overflow-hidden rounded-2xl">
        <div className="absolute inset-0 flex items-center justify-center opacity-5">
          <div className="w-[800px] h-[800px] bg-gradient-to-r from-blue-200 to-purple-200 rounded-full blur-3xl animate-pulse" />
        </div>

        <div
          className="absolute inset-0"
          style={{
            backgroundImage:
              "radial-gradient(circle at 1px 1px, #f0f0f0 1px, transparent 0)",
            backgroundSize: "40px 40px",
          }}
        ></div>

        <div className="relative z-10 container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="font-mono text-4xl md:text-6xl font-bold text-black mb-6 tracking-tight">
              AAAGentive
            </h1>

            <h1 className="font-mono mt-20 text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold text-black mb-6 tracking-tight leading-relaxed">
              AI_AGENTS.init(
              <br className="md:hidden" />
              <span className="text-blue-600">future</span>
              <br className="md:hidden" />)
            </h1>

            <p className="font-mono text-gray-600 text-lg md:text-xl mb-12 tracking-wide">
              $ deploying intelligent solutions for solo founders_
            </p>

            <button className="bg-black hover:bg-gray-800 text-white px-8 py-4 rounded-lg font-mono transition-colors">
              {">"} initialize_platform()
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
