import React from "react";

const AgentGrid = ({ title = "Marketing", agentCluster = [] }) => {
  return (
    <div className="w-full bg-black py-12 md:py-20">
      <div className="max-w-7xl mx-auto px-4">
        {/* Title Section */}
        <div className="mb-12 text-center">
          {/* <h1 className="text-4xl md:text-5xl font-bold tracking-tight text-white">
            {title}
          </h1> */}
          <h1 className="font-mono  text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold  text-white mb-6 tracking-tight leading-relaxed">
            {title}
          </h1>
          <p className="mt-4 text-lg text-white/70">
            Explore our AI agents designed to supercharge your workflow.
          </p>
        </div>

        {/* Grid Layout for Agents */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-8">
          {agentCluster.map((agent, index) => (
            <div
              key={index}
              className={`rounded-2xl overflow-hidden bg-gradient-to-br ${agent.gradient} p-6 md:p-8 relative group hover:shadow-lg transition-all border border-white/20`}
            >
              <div className="relative z-10 h-full flex flex-col justify-between text-white">
                {/* Icon and Title */}
                <div>
                  <div className="flex items-center gap-3 mb-4">
                    {agent.icon}
                    <span
                      className={`text-${agent.accent}-300 text-sm font-medium`}
                    >
                      AI Agent
                    </span>
                  </div>
                  <h2 className="text-lg sm:text-xl md:text-2xl font-bold mb-2">
                    {agent.title}
                  </h2>
                  <p className="text-sm md:text-base text-white/70 line-clamp-3 mb-4">
                    {agent.description}
                  </p>
                </div>

                {/* Key Features */}
                <ul className="text-sm md:text-base text-white/70 mb-4">
                  {agent.keyFeatures.map((feature, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <span className={`text-${agent.accent}-300`}>•</span>{" "}
                      {feature}
                    </li>
                  ))}
                </ul>

                {/* Status Indicator */}
                <div className="flex items-center gap-3 mt-4">
                  <div className="w-2 h-2 bg-yellow-500 rounded-full animate-pulse" />
                  <span className="text-xs text-white/60">{agent.status}</span>
                </div>
              </div>

              {/* Gradient Overlay and Background Effects */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
              <div
                className={`absolute -right-24 -bottom-24 w-64 h-64 md:w-96 md:h-96 bg-${agent.accent}-500/20 blur-3xl rounded-full`}
              />
              <div
                className={`absolute -left-24 -top-24 w-64 h-64 md:w-96 md:h-96 bg-${agent.accent}-500/10 blur-3xl rounded-full`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AgentGrid;
